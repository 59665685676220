import React from "react"
import Img from "gatsby-image"
import st from "./onsale-card.module.scss"

/*
PROPS:
  id
  title
  link
  originalPrice
  salePrice
  thumbnail {
    fluid
  }
*/

/*
this works, too:
  fluid={{...props.thumbnail.fluid, aspectRatio: 1}}
*/

const OnsaleCard = props => (
  <article key={props.id} className={st.onsale}>
    <a href={props.link} target="_blank" rel="noopener noreferrer">
      <Img
        alt={props.thumbnail.title}
        fluid={{...props.thumbnail.fluid, aspectRatio: 1}}
        className={st.img}
      />
      <h2 className={st.title}>{props.title}</h2>
      <div className={st.prices}>
        <span className={`${st.priceSale} currency-usd`}>
          {props.salePrice}
        </span>
        <span className={`${st.priceOriginal} currency-usd`}>
          {props.originalPrice}
        </span>
      </div>
    </a>
  </article>
)

export default OnsaleCard
