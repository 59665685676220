import React from "react"
import { graphql } from "gatsby"
import st from "./index.module.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import FeaturedContainer from "../components/featured-container"
import OnsaleContainer from "../components/onsale-container"
import PostGeneralContainer from "../components/postGeneral-container"

export default ({ data }) => (
  <Layout>
    <SEO
      title="Fashion & Style"
      image={data.postsFeatured.nodes[0].thumbnail.fixed.src}
      imageWidth={data.postsFeatured.nodes[0].thumbnail.fixed.width}
      imageHeight={data.postsFeatured.nodes[0].thumbnail.fixed.height}
    />

    <div className={st.sectionContainer}>
      <header>
        <h1>Featured Posts</h1>
      </header>
      <FeaturedContainer posts={data.postsFeatured.nodes} />
    </div>

    <div className={st.sectionContainer}>
      <header>
        <h1>On Sale Now</h1>
        <h2>
          This is what everyone is looking at right now. Check them out now before it's too late.
        </h2>
      </header>
      <OnsaleContainer posts={data.postsOnsale.nodes} />
    </div>

    <div className={st.sectionContainer}>
      <header>
        <h1>Latest Posts</h1>
      </header>
      <PostGeneralContainer posts={data.postsGeneral.nodes} />
    </div>
  </Layout>
)

export const query = graphql`
  {
    postsFeatured: allContentfulPostGeneral(
      filter: { featured: { eq: true } }
      sort: { fields: createdAt, order: DESC }
      limit: 2
    ) {
      nodes {
        id
        category
        title
        slug
        createdAt(formatString: "dddd MMM DD YYYY")
        featured
        thumbnail {
          title
          fluid(maxWidth: 600, maxHeight: 600, quality: 50) {
            ...GatsbyContentfulFluid
          }
          fixed (width: 500) {
            src
            width
            height
          }
        }
      }
    }
    postsOnsale: allContentfulPostOnsale(
      sort: { fields: createdAt, order: DESC }
      limit: 6
    ) {
      nodes {
        id
        title
        link {
          link
        }
        originalPrice
        salePrice
        thumbnail {
          title
          sizes(maxWidth: 300, maxHeight: 300, quality: 40) {
            ...GatsbyContentfulSizes
          }
          fluid(
            maxWidth: 300
            maxHeight: 300
            quality: 40
            resizingBehavior: FILL
          ) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
    postsGeneral: allContentfulPostGeneral(
      sort: { fields: createdAt, order: DESC }
      limit: 12
    ) {
      nodes {
        id
        category
        title
        slug
        createdAt(formatString: "dddd MMM DD YYYY")
        featured
        thumbnail {
          title
          fluid(maxWidth: 600, maxHeight: 600, quality: 50) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
