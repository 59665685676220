import React from "react"
import st from "./featured-container.module.scss"
import FeaturedCard from "../components/featured-card"

const FeaturedContainer = props => (
  <section className={st.featuredContainer}>
    {props.posts.map(post => (
      <FeaturedCard 
        key={post.id}
        id={post.id}
        category={post.category}
        title={post.title}
        slug={`${post.category}/${post.slug}`}
        createdAt={post.createdAt}
        featured={post.featured}
        thumbnail={post.thumbnail}
      />
    ))}
  </section>
)

export default FeaturedContainer
