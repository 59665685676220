import React from "react"
import st from "./onsale-container.module.scss"
import OnsaleCard from "../components/onsale-card"

const OnsaleContainer = props => (
  <section className={st.onsaleContainer}>
    {props.posts.map(post => (
      <OnsaleCard
        key={post.id}
        id={post.id}
        title={post.title}
        link={post.link.link}
        originalPrice={post.originalPrice}
        salePrice={post.salePrice}
        thumbnail={post.thumbnail}
      />
    ))}
  </section>
)

export default OnsaleContainer
